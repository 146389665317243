import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/index.module.css';

class StickyPanel extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    adjustment: PropTypes.number,
    fixedClass: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    adjustment: 0,
    fixedClass: '',
    style: {},
  };

  state = { isSticky: false };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    this.rect = this.sticky.getBoundingClientRect();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.rect = this.sticky.getBoundingClientRect();
  }

  handleScroll = (event) => {
    const scrollY = event.target.scrollingElement.scrollTop + this.props.adjustment;

    let isSticky = false;
    if (scrollY > this.rect.top) {
      isSticky = true;
    }

    if (this.state.isSticky !== isSticky) {
      this.setState({ isSticky });
    }
  };

  render() {
    const { fixedClass } = this.props;
    const { isSticky } = this.state;

    const style = this.rect
      ? {
        left: this.rect.left,
        top: this.props.adjustment,
        width: this.rect.width,
        ...this.props.style,
      }
      : null;

    if (isSticky) {
      return (
        <div>
          <div style={ { height: `${this.rect.height}px` } } />
          <div
            className={ isSticky ? `${styles.fixed} ${fixedClass || ''}` : '' }
            style={ style }
            ref={ (el) => { this.sticky = el; } }
          >
            { this.props.children }
          </div>
        </div>
      );
    }

    return (
      <div
        className={ isSticky ? `${styles.fixed} ${fixedClass || ''}` : '' }
        style={ style }
        ref={ (el) => { this.sticky = el; } }
      >
        { this.props.children }
      </div>
    );
  }
}

export default StickyPanel;
